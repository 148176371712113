import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import {
  FileGeneratorType,
  mapGeneratorsToFiles,
} from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetModelsApiArg,
  GetModelsByModelIdApiResponse,
  useGetModelsQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

type UseAllGeneratorsArgs = {
  statusFilter?: GetModelsByModelIdApiResponse["model"]["status"];
  privacyFilter?: "public" | "private";
  searchTerm?: string;
  customFilter?: (file: FileGeneratorType) => boolean;
  collectionId?: string;
  // sortBy?: GetModelsApiArg["sortBy"];
  // sortDirection?: GetModelsApiArg["sortDirection"];
};

export default function useAllGenerators({
  statusFilter,
  privacyFilter,
  searchTerm,
  customFilter,
  collectionId,
}: // sortBy,
// sortDirection,
UseAllGeneratorsArgs) {
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();
  const debouncedRequestArgs = useDebounce<GetModelsApiArg | typeof skipToken>(
    {
      privacy: privacyFilter,
      status: statusFilter,
      pageSize: "50",
      teamId: selectedTeam.id,
      paginationToken: paginationToken,
      collectionId: collectionId,
      // sortBy,
      // sortDirection,
    },
    100
  );

  useEffect(() => {
    setPaginationToken(undefined);
  }, [statusFilter, privacyFilter, collectionId /*, sortBy, sortDirection*/]);

  const { data, isLoading, error, refetch } =
    useGetModelsQuery(debouncedRequestArgs);

  useEffect(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data?.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  const files = useMemo(
    // FIXME: create a correct Model type based on the api instead of manually casting
    () => mapGeneratorsToFiles((data?.models || []) as any[]),
    [data?.models]
  );

  const filteredFiles = useMemo(() => {
    return files.filter((file) => {
      const searchMatch =
        !searchTerm ||
        file.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const statusMatch = !statusFilter || file.meta.status === statusFilter;
      const filterMatch = !customFilter || customFilter(file);
      return searchMatch && statusMatch && filterMatch;
    });
  }, [files, customFilter, searchTerm, statusFilter]);

  return {
    files: filteredFiles,
    isLoading,
    refetch,
    error,
    hasMore: !!data?.nextPaginationToken,
    loadMore: () => {},
    unfiltersFiles: files,
  };
}
