import React, { useCallback } from "react";
import CollectionMenuList from "domains/collections/components/CollectionMenuList";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetModelsByModelIdApiResponse,
  useDeleteModelsByCollectionIdMutation,
  usePutModelsByCollectionIdMutation,
} from "infra/api/generated/api";

import { PlacementWithLogical } from "@chakra-ui/popper/dist/popper.placement";

export interface ModelCollectionProps {
  models: GetModelsByModelIdApiResponse["model"][];
  menuPlacement: PlacementWithLogical;
}

const ModelCollection = ({ models, menuPlacement }: ModelCollectionProps) => {
  const { selectedTeam } = useTeamContext();
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { collections, isLoading: isLoadingCollections } = useAllCollections();

  const isInCollection = useCallback(
    (collectionId: string) => {
      if (!models.length) {
        return false;
      }
      return !models.some(
        (model) => !model.collectionIds.includes(collectionId)
      );
    },
    [models]
  );

  const [addModelToCollectionTrigger] = usePutModelsByCollectionIdMutation();
  const [removeModelFromCollectionTrigger] =
    useDeleteModelsByCollectionIdMutation();
  const toggleModelsFromCollection = useCallback(
    async (collectionId: string) => {
      const add = !isInCollection(collectionId);
      const modelIds = models
        .filter((model) => add !== model.collectionIds.includes(collectionId))
        .map((model) => model.id);
      const trigger = add
        ? addModelToCollectionTrigger
        : removeModelFromCollectionTrigger;
      try {
        await trigger({
          teamId: selectedTeam.id,
          collectionId,
          body: {
            modelIds: modelIds,
          },
        }).unwrap();
        successToast({
          title: `Generator${models.length > 1 ? "s" : ""} ${
            add ? "added to" : "removed from"
          } collection`,
        });
      } catch (error) {
        handleApiError(
          error,
          `Error ${add ? "adding" : "removing"} generator${
            models.length > 1 ? "s" : ""
          } ${add ? "to" : "from"} collection`
        );
      }
    },
    [
      isInCollection,
      models,
      addModelToCollectionTrigger,
      removeModelFromCollectionTrigger,
      selectedTeam.id,
      handleApiError,
      successToast,
    ]
  );

  return (
    <CollectionMenuList
      collections={collections}
      isLoadingCollections={isLoadingCollections}
      toggleFromCollection={toggleModelsFromCollection}
      isInCollection={isInCollection}
      placement={menuPlacement}
    />
  );
};

export default ModelCollection;
