import React from "react";
import { useRouter } from "next/router";
import ModelCollection from "domains/collections/components/ModelCollection";
import DefaultFilePreview, {
  FilePreviewProps,
} from "domains/file-manager/components/FilePreview";
import { DEFAULT_MODEL_THUMBNAIL } from "domains/generators/utils";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import ManageTags from "domains/tags/components/ManageTags";
import SelectionBarTags from "domains/tags/components/SelectionBarTags";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { useHandleApiError } from "infra/api/error";
import {
  useDeleteModelsByModelIdMutation,
  useGetModelsByModelIdQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/query";

import { FileGeneratorType, FileHandler } from "../interfaces";

interface FileGeneratorHandlerProps {
  emptyState?: JSX.Element;
  files?: FileGeneratorType[];
}

export const useFileGeneratorHandler = ({
  emptyState,
}: FileGeneratorHandlerProps): FileHandler<FileGeneratorType> => {
  const router = useRouter();
  const { successToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();

  const [triggerDeleteGenerator, { isLoading: isDeleteGeneratorLoading }] =
    useDeleteModelsByModelIdMutation();

  const isLoading = isDeleteGeneratorLoading;

  const handleDelete = async (files: FileGeneratorType[]) => {
    try {
      await Promise.all(
        files.map((file) =>
          triggerDeleteGenerator({
            teamId: selectedTeam.id,
            modelId: file.id,
          })
        )
      );
      successToast({
        title: "Generator(s) deleted",
      });
    } catch (error) {
      handleApiError(error, "There was an error deleting the generator(s)");
    }
  };

  return {
    EmptyState: emptyState,
    FilePreview: FileGeneratorPreview,
    onOpen: (file) => {
      void router.push({
        pathname: "/generators/[id]",
        query: { id: file.id },
      });
    },
    actions: [
      {
        kind: ["selectionBar"],
        label: "Delete",
        onAction: handleDelete,
        Component: ({ onAction }) => (
          <ButtonWithModal
            variant="secondary"
            colorScheme={"danger"}
            leftIcon={<Icon id="Ui/Trash" />}
            onConfirm={onAction}
            modalHeader={"Delete generators"}
            modalBody={"Are you sure you want delete these generators?"}
            isLoading={isDeleteGeneratorLoading}
            isModalConfirmButtonLoading={isDeleteGeneratorLoading}
            isDisabled={isLoading}
          >
            Delete
          </ButtonWithModal>
        ),
      },
      {
        kind: ["selectionBar"],
        label: "Collections",
        onAction: (_) => {},
        Component: ({ files }) => (
          <ModelCollection
            models={files.map((item) => item.meta)}
            menuPlacement="top"
          />
        ),
      },
      {
        kind: ["selectionBar"],
        label: "Tags",
        onAction: (_) => {},
        Component: ({ files }) => (
          <SelectionBarTags length={files.length}>
            <ManageTags items={files.map((item) => item.meta)} />
          </SelectionBarTags>
        ),
      },
    ],
  };
};

const FileGeneratorPreview = (props: FilePreviewProps<FileGeneratorType>) => {
  const { selectedTeam } = useTeamContext();

  const id = props.file.id;
  const trainingImages = props.file.meta.trainingImages;
  const thumbnail = props.file.thumbnail;
  useGetModelsByModelIdQuery(
    trainingImages === undefined && thumbnail === DEFAULT_MODEL_THUMBNAIL
      ? { teamId: selectedTeam.id, modelId: id }
      : skipToken,
    {
      selectFromResult: ({ data }) => {
        return {
          actualTrainingImages: data?.model?.trainingImages ?? [],
        };
      },
    }
  );

  // FIXME: fix type, like in FileView, this is a problem with the fileHandler
  return <DefaultFilePreview {...(props as any)} />;
};
