import React from "react";
import { NextSeo } from "next-seo";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import CanvasList from "domains/canvasv2/components/CanvasList";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import FileManager from "domains/file-manager/components/FileManager";
import { useFileGeneratorHandler } from "domains/file-manager/hooks/useFileGeneratorHandler";
import { useFileImageHandler } from "domains/file-manager/hooks/useFileImageHandler";
import useAllGenerators from "domains/generators/hooks/useAllGenerators";
import Header from "domains/ui/components/Header";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "domains/ui/components/Tabs";
import { createTabs, useTabs } from "domains/ui/hooks/useTabs";
import { FormattedMessage, useIntl } from "react-intl";

const TABS = createTabs({
  ids: ["IMAGES", "GENERATORS", "CANVAS"],
  items: {
    IMAGES: "images",
    GENERATORS: "generators",
    CANVAS: "canvas",
  },
});

export default function Home() {
  const { tabIndex, onTabChange } = useTabs(TABS);
  const { allAssetsSortArgs, assetFileManagerSortOptions } = useAssetSort();
  const { allAssetsTypeArgs, assetFileManagerTypeOptions } = useAssetFilterType(
    { defaultValue: "inference" }
  );

  const allGeneratorsQuery = useAllGenerators({});
  const allAssetsQuery = useAllAssets({
    ...allAssetsSortArgs,
    ...allAssetsTypeArgs,
  });

  const imageFileHandler = useFileImageHandler({});
  const generatorFileHandler = useFileGeneratorHandler({});

  const intl = useIntl();

  return (
    <>
      <NextSeo
        title={intl.formatMessage({
          id: "pages.index.title",
          defaultMessage: "Home",
        })}
      />

      <Header
        title={intl.formatMessage({
          id: "pages.index.title",
          defaultMessage: "Home",
        })}
        altActions={[
          {
            id: "image",
            label: intl.formatMessage({
              id: "pages.index.generateImages",
              defaultMessage: "Generate Images",
            }),
            primaryIcon: "Nav/Image/Solid",
            rightIcon: "Ui/Plus",
            buttonProps: {
              internalLink: "/images/new",
            },
          },
          {
            id: "generator",
            label: intl.formatMessage({
              id: "pages.index.createGenerator",
              defaultMessage: "Create Generator",
            }),
            primaryIcon: "Nav/Generator/Solid",
            rightIcon: "Ui/Plus",
            buttonProps: {
              internalLink: "/generators/new",
            },
          },
          {
            id: "canvas",
            label: intl.formatMessage({
              id: "pages.index.createCanvas",
              defaultMessage: "Create Canvas",
            }),
            primaryIcon: "Nav/Canvas/Solid",
            rightIcon: "Ui/Plus",
            buttonProps: {
              internalLink: "/canvas/new",
            },
          },
        ]}
      />

      {tabIndex !== undefined && (
        <Tabs isLazy index={tabIndex} onChange={onTabChange} mx={9}>
          <TabList>
            <Tab>
              <FormattedMessage
                id="pages.index.tabs.images"
                defaultMessage="Images"
              />
            </Tab>
            <Tab>
              <FormattedMessage
                id="pages.index.tabs.generators"
                defaultMessage="Generators"
              />
            </Tab>
            <Tab>
              <FormattedMessage
                id="pages.index.tabs.canvas"
                defaultMessage="Canvas"
              />
            </Tab>
          </TabList>

          <TabIndicator />

          <TabPanels>
            <TabPanel>
              <FileManager
                files={allAssetsQuery.files}
                isLoading={allAssetsQuery.isLoading}
                loadMore={allAssetsQuery.loadMore}
                hasMore={allAssetsQuery.hasMore}
                fileHandlers={{
                  image: imageFileHandler,
                }}
                options={{
                  cacheKey: PersistedStateKey.FILE_MANAGER_IMAGE,
                  canSelect: true,
                  canChangeNumberOfColumns: true,
                  canChangeView: true,
                  ...assetFileManagerSortOptions,
                  ...assetFileManagerTypeOptions,
                }}
              />
            </TabPanel>

            <TabPanel>
              <FileManager
                files={allGeneratorsQuery.files}
                isLoading={allGeneratorsQuery.isLoading}
                loadMore={allGeneratorsQuery.loadMore}
                hasMore={allGeneratorsQuery.hasMore}
                options={{
                  cacheKey: PersistedStateKey.FILE_MANAGER_GENERATOR,
                  canSelect: true,
                  canChangeView: false,
                  canChangeNumberOfColumns: true,
                  showFileNames: "always",
                }}
                fileHandlers={{ generator: generatorFileHandler }}
              />
            </TabPanel>

            <TabPanel>
              <CanvasList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
}
